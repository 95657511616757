import React from 'react';
import { Image } from '@nextui-org/react';
import PartTabs from "./main_content/PartTabs";
import EngineeringTools from "./main_content/EngineeringTools";
import Preview from 'images/home/ems-admin-preview.png';
import BomQuoteImage from 'images/home/bom-quote.png';

const MainContent = () => (
  <div className="main-content">
    <div className="container">
      <div className="grid grid-cols-12 gap-4 lg:gap-20 lg:items-center">
        <div className="col-span-12 md:col-span-12 lg:col-span-12">
          <h2 className="h2 section-title"> Electronic Part Search Engine</h2>
          <PartTabs />
        </div>
      </div>
    </div>
    <div className="container">
      <div className='flex justify-center items-center'>
        <Image src={BomQuoteImage} alt="Bom Quote" height={671} width={1117}/>
      </div>
    </div>
  </div>
)

export default MainContent;
