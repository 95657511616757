import React, {useState} from 'react';
import { Tabs, Tab, cn, Divider } from "@nextui-org/react";
import FindPart from "./FindPart";
import UploadBom from "./UploadBom";

const TabTitle = ({title, tab, currentTab}) => {
  return (
    <div className=''>
      <h4>{title}</h4>
      {tab === currentTab && 
        <div className='border-t-2 border-t-[#FFFFFF] mt-[0.313rem]'/>
      }
    </div>
  )
}

const PartTabs = () => {
  const [currentTab, setCurrentTab] = useState('bom-quote');

  return (
    <div className={cn([
      "part-tabs",
      "flex justify-center",
    ])}>
      <div className="tab-tools">
        <Tabs
          aria-label="Options"
          variant="light"
          selectedKey={currentTab}
          onSelectionChange={(key) => setCurrentTab(key)}
          classNames={{
            tab: cn([
              'rounded-t-lg rounded-b-none h-12 px-6 font-bold tab-item bg-[#1A222D]',
              'border-t border-x border-t-[#818F99] border-x-[#818F99] border-b border-b-[#818F99]',
              'data-[selected=true]:border-b-[#1A222D]',
            ]),
            tabList: 'rounded-none tab-list',
            cursor: 'rounded-t-lg rounded-b-none bg-[#1A222D]',
            panel: 'p-0 rounded-b-lg rounded-tr-lg tab-panels bg-[#1A222D] border border-[#818F99] -mt-[1px]',
            tabContent: 'tab-content',
          }}
        >
          <Tab key="bom-quote" title={<TabTitle title="BOM Quote" tab="bom-quote" currentTab={currentTab}/>}>
            <UploadBom />
          </Tab>
          <Tab key="find-part" title={<TabTitle title="Find Part" tab="find-part" currentTab={currentTab}/>}>
            <div className="part-search">
              <FindPart />
              <div className="mt-4 text-base">
                You can search by part number, partial part number, or keyword, like “S-35390A-T8T1G,” “RC0402,” or
                “Op-Amp” to find matching parts.
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default PartTabs;
