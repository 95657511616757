import React, { useState, useEffect, useCallback } from 'react';
import { toast } from "react-toastify";
import { useDropzone } from 'react-dropzone'
import { Button, cn, Input, useDisclosure } from '@nextui-org/react';
import { CirclePlus, CircleMinus, ArrowRight, Upload } from 'lucide-react';

// components
import LoginModal from "src/shared/components/LoginModal";
import { isUserLoggedIn } from 'src/shared/utils/session'

import {useValidator} from "src/shared/utils/use_validator";

// apis
import { useCreateNewBomMutation } from "src/services/rtk_api/bom/BomApi";
import classNames from 'classnames';


const UploadBom = () => {
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectRevision, setProjectRevision] = useState('');
  const [boardQtys, setBoardQtys] = useState(['']);
  const [validator, forceUpdate] = useValidator({},{});

  const [
    createNewBom, {
      isSuccess,
      isError,
      data: new_bom
    }
  ] = useCreateNewBomMutation({fixedCacheKey: 'shared-create-bom'});

  const {
    isOpen: isModalOpen, 
    onOpen: onModalOpen, 
    onOpenChange: onModalOpenChange,
    onClose: onModalClose,
  } = useDisclosure();

  const onAddBoardQty = () => {
    if(boardQtys.length < 4) setBoardQtys([...boardQtys, null]);
  }

  const onRemoveLastBoardQty = () => {
    if (boardQtys.length > 1) setBoardQtys(boardQtys.slice(0, boardQtys.length - 1));
  }

  const onChangeBoardQty = (index, value) => {
    setBoardQtys(boardQtys.map((board_qty, i) => i === index ? value : board_qty));
  }

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    // only alert customer errors if no one file was accepted
    if (acceptedFiles.length === 0 && fileRejections.length > 0) {
      fileRejections.forEach((item) => {
        const text = `${item.file.name} ` + item.errors.map((error) => error.code).join(', ')
        toast.error(text);
      });
      setAcceptedFile(null)
      return;
    }

    setFileName(acceptedFiles[0].name);
    setAcceptedFile(acceptedFiles[0]);

    if(!projectName) setProjectName(acceptedFiles[0].name.replace(/\.[^/.]+$/, ''));
  }, []);

  const onStartQuote = () => {
    if(!validator.allValid()) {
      validator.showMessages();
      return;
    }

    if(!acceptedFile){
      toast.error("Please upload a BOM file");
      return;
    }
    
    if(!isUserLoggedIn()) {
      onModalOpen();
      return;
    }

    processFileSelected(acceptedFile);
  }

  const processFileSelected = (file) => {
    if (!file) return;

    const form_data = new FormData()
    form_data.append('bom[file]', file)
    form_data.append('bom[origin]', 'upload')
    form_data.append('bom[name]', projectName)
    form_data.append('bom[revision]', projectRevision)
    form_data.append('bom[board_qtys]', boardQtys.join(','))
    createNewBom({formData: form_data})
  }

  useEffect(() => {
    if (isSuccess) window.location.href = `/boms/${new_bom.id}/parse`
  }, [isSuccess])

  useEffect(() => {
    if (isError) toast.error("There was an error uploading the new BOM file\nPlease try again")
  }, [isError])

  const {getRootProps, getInputProps, open: openFileChoose} = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
    },
    maxFiles: 1,
  })

  const onCancelModal = () => {
    onModalClose();
  }

  const onOkModal = () => {
    onModalClose();
    processFileSelected(acceptedFile);
  }

  const onPressUpload = () => {
    openFileChoose();
  }

  return (
    <div className={classNames(cn([
      'w-full md:w-[813px]' 
    ]), {
    })}>
      <div>

      </div>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 md:col-span-6 lg:col-span-7'>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-12 md:col-span-8 lg:colspan-8'>
              <span className='text-[1rem] leading-[1.21rem]'>Project Name</span>
              <Input 
                classNames={{
                  base: 'mt-1',
                }} 
                type='text'
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                />
              {validator && validator.message(`Project Name`, projectName, 'required')}
            </div>
            <div className='col-span-12 md:col-span-4 lg:colspan-4'>
              <span className='text-[1rem] leading-[1.21rem]'>Revision</span>
              <Input
                classNames={{
                  base: 'mt-1',
                }} 
                type='text'
                value={projectRevision}
                onChange={(e) => setProjectRevision(e.target.value)}
                />
              {validator && validator.message(`Revision`, projectRevision, 'required')}
            </div>
            <div className='col-span-12' {...getRootProps()}>
              <input {...getInputProps()} />
              <Input 
                type='text'
                value={fileName}
                isReadOnly={true}
                classNames={{
                  base: 'h-12',
                  inputWrapper: 'h-12'
                }}
                startContent={
                  <Button
                    className={cn([
                      "h-[34px] w-[198px]",
                      "pl-[0.4375rem] pr-[0.5625rem]",
                      "text-[1rem] leading-[1.21rem]",
                      "text-center text-[#1D293A] font-semibold"
                    ])}
                    onPress={onPressUpload}
                  >
                    <span className='inline-flex items-center justify-center'>
                      <Upload size={22} strokeWidth={2} />
                      &nbsp;Upload BOM
                    </span>
                  </Button>
                }
                />
              <div className="mt-[0.563rem] text-base font-medium">
                In one of the following formats: .xls, xlsx, .csv
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-12 md:col-span-6 lg:col-span-5'>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-12'>
              <span className='text-[1rem] leading-[1.21rem]'>Board Qty</span>
              <div className="flex content-board-qty mt-1">
                <div className="flex gap-x-2 inputs">
                  {boardQtys && boardQtys.map((board_qty, index) => (
                    <div key={index} className="">
                      <Input
                        key={`board_qty_${index}`}
                        type="text"
                        value={board_qty || ''}
                        className="bg-white"
                        onChange={(e) => onChangeBoardQty(index, e.target.value)}
                      />
                      {validator && validator.message(`Board Quantity`, board_qty, 'required|numeric|min:1,num')}
                    </div>
                  ))}
                </div>
                <div className="flex flex-col controls gap-y-2">
                  <Button size="sm" className="h-min" isIconOnly variant="light" onClick={onAddBoardQty}>
                    <CirclePlus size={16} color="#ffffff"/>
                  </Button>
                  <Button size="sm" className="h-min" isIconOnly variant="light" onClick={onRemoveLastBoardQty}>
                    <CircleMinus size={16} color="#ffffff"/>
                  </Button>
                </div>
              </div>
            </div>
            <div className='col-span-12'>
              <Button
                color="warning"
                className={cn([
                  "h-12 px-6 w-full text-lg font-extrabold",
                  'bg-[#007CA1] hover:bg-[#005D7B] active:bg-[#004A63]',
                ])}
                
                onPress={onStartQuote}
              >
                <span className='inline-flex items-center justify-center'>
                  Start Quote&nbsp;
                  <ArrowRight width={21.67} height={24} strokeWidth={3}/>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        onOk={onOkModal}
        onCancel={onCancelModal} />
    </div>
  )
}

export default UploadBom;