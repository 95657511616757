import React from 'react';
import { Image } from '@nextui-org/react';

import arrow from 'images/distributors/arrow.png';
import avnet from 'images/distributors/avnet.png';
import coilcraft from 'images/distributors/coilcraft.png';
import digikey from 'images/distributors/digikey.png';
import futureElectronics from 'images/distributors/future-electronics.png';
import microchip from 'images/distributors/microchip.png';
import mouser from 'images/distributors/mouser.png';
import vertical from 'images/distributors/vertical.png';
import elemental14 from 'images/distributors/element-14.png';
import tti from 'images/distributors/tti.png';
import tme from 'images/distributors/tme.png';
import newark from 'images/distributors/newark.png';
import farnell from 'images/distributors/farnell.png';
import classNames from 'classnames';

const DistributorImg = ({src, distributor, imgClassNames=""}) => {
  return (
    <div className="border rounded-[10px] px-8 py-[1.0625rem] flex justify-center items-center">
      <Image
        src={src} 
        alt={`distributors ${distributor}`} 
        className={classNames("max-h-[69px] max-w-[142px]", imgClassNames)}/>
    </div>
  )
}

const Distributors = () => {
  return (
    <div className="container pt-14 lg:pt-28 pb-20 lg:pb-36 distributors">
      <h2 className="title-section font-bold">Search Parts by Select Distributors</h2>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8 lg:grid-cols-5 mt-14">
        <DistributorImg src={digikey} distributor="digikey"/>
        <DistributorImg src={arrow} distributor="arrow"/>
        <DistributorImg src={vertical} distributor="vertical"/>
        <DistributorImg src={mouser} distributor="mouser"/>
        <DistributorImg src={tti} distributor="tti"/>
        <DistributorImg src={avnet} distributor="avnet"/>
        <DistributorImg src={newark} distributor="newark"/>
        <DistributorImg src={farnell} distributor="farnell"/>
        <DistributorImg src={elemental14} distributor="element14"/>
        <DistributorImg src={tme} distributor="tme" imgClassNames='h-[50px]'/>
      </div>
    </div>
  )
}

export default Distributors;
