import {useRef, useState}     from 'react'
import SimpleReactValidator   from 'simple-react-validator';

export const useValidator = (customMessage = {}, customValidator = {}) => {
  const [, forceUpdate] = useState();

  const validator = useRef(new SimpleReactValidator({
    messages: customMessage,
    validators: customValidator,
    autoForceUpdate: {forceUpdate: () => forceUpdate(1)}
  }));

  return [validator.current, forceUpdate];
}